import { ContentTypeEnum } from '@/enums/http-enum'

/**
 * 根据id查询电视墙详情
 */
export const getTvWallApi = (id: number) => useGet<TvWallDetail>(`/runtime/tv-wall/${id}`, {}, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 删除电视墙
 */
export const deleteTvWallApi = (id: number) => useDelete<boolean>(`/runtime/tv-wall/${id}`)

/**
 * 查询公共的电视墙
 */
export const queryPublicTvWallsApi = () => useGet<TvWall[]>('/runtime/tv-wall/publics', {}, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 查询私有的电视墙
 */
export const queryPrivateTvWallsApi = () => useGet<TvWall[]>('/runtime/tv-wall/privates', {}, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 新增或修改电视墙
 */
export const updateTvWallApi = (data: Partial<TvWallDetail>) => usePost<number>('/runtime/tv-wall', data)
